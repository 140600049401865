// PostToolbar.js
import React, { useState, useRef, useEffect } from 'react';
import { Camera, Smile, Hash, Link2 } from 'lucide-react';

const PostToolbar = ({ content, onContentChange }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showHashtags, setShowHashtags] = useState(false);
  const toolbarRef = useRef(null);
  const characterLimit = 3000;

  // Extended emoji list with categories
  const emojiCategories = {
    "Smileys & People": ['😀', '😃', '😄', '😁', '😅', '😂', '🤣', '😊', '😇', '🙂', '😉', '😍', '🥰', '😘'],
    "Gestures": ['👍', '👎', '👏', '🙌', '🤝', '🤲', '🫶', '💪', '🤜', '🤛', '✌️', '🫰', '🤌', '👌'],
    "Hearts & Love": ['❤️', '🧡', '💛', '💚', '💙', '💜', '🤎', '🖤', '💖', '💗', '💓', '💝', '💕', '💞'],
    "Business": ['💼', '💻', '📱', '📊', '📈', '🎯', '⭐', '💡', '🚀', '📝', '✍️', '🤝', '💪', '🎉'],
    "Nature": ['🌟', '⭐', '✨', '💫', '☀️', '🌈', '🌸', '🌺', '🌼', '🌻', '🍀', '🌿', '🌱', '🌳']
  };

  // Extended hashtag suggestions with categories
  const hashtagCategories = {
    "Business": [
      'business', 'entrepreneur', 'startup', 'smallbusiness', 'marketing',
      'leadership', 'success', 'motivation', 'businesstips', 'entrepreneurlife'
    ],
    "Marketing": [
      'digitalmarketing', 'socialmedia', 'contentmarketing', 'branding',
      'marketingstrategy', 'growthhacking', 'sem', 'seo', 'emailmarketing', 'advertising'
    ],
    "Technology": [
      'tech', 'technology', 'innovation', 'ai', 'artificialintelligence',
      'blockchain', 'coding', 'programming', 'software', 'cybersecurity'
    ],
    "Professional": [
      'career', 'networking', 'professionaldevelopment', 'jobsearch',
      'resume', 'careeradvice', 'worklife', 'remotework', 'hiring', 'jobs'
    ],
    "Industry": [
      'fintech', 'ecommerce', 'saas', 'realestate', 'healthcare',
      'education', 'retail', 'consulting', 'manufacturing', 'logistics'
    ]
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
        setShowHashtags(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const insertTextAtCursor = (textToInsert) => {
    const textarea = document.querySelector('textarea');
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const newContent = 
      content.substring(0, start) + 
      textToInsert + 
      content.substring(end);

    onContentChange(newContent);
    
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = start + textToInsert.length;
      textarea.focus();
    }, 0);
  };

  return (
    <div className="flex items-center justify-between border-t border-b py-3" ref={toolbarRef}>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <button 
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Smile className="w-5 h-5 text-gray-600" />
          </button>
          {showEmojiPicker && (
            <div className="absolute bottom-full mb-2 bg-white shadow-lg rounded-lg p-4 w-[320px] max-h-[400px] overflow-y-auto z-50">
              {Object.entries(emojiCategories).map(([category, emojis]) => (
                <div key={category} className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">{category}</h3>
                  <div className="grid grid-cols-7 gap-2">
                    {emojis.map((emoji, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          insertTextAtCursor(emoji);
                          setShowEmojiPicker(false);
                        }}
                        className="p-2 hover:bg-gray-100 rounded text-xl transition-colors"
                      >
                        {emoji}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="relative">
          <button 
            onClick={() => setShowHashtags(!showHashtags)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Hash className="w-5 h-5 text-gray-600" />
          </button>
          {showHashtags && (
            <div className="absolute bottom-full mb-2 bg-white shadow-lg rounded-lg p-4 w-[320px] max-h-[400px] overflow-y-auto z-50">
              {Object.entries(hashtagCategories).map(([category, hashtags]) => (
                <div key={category} className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">{category}</h3>
                  <div className="grid grid-cols-2 gap-2">
                    {hashtags.map((tag, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          insertTextAtCursor(` #${tag} `);
                          setShowHashtags(false);
                        }}
                        className="text-left px-3 py-2 hover:bg-gray-100 rounded text-sm transition-colors truncate"
                      >
                        #{tag}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <button 
          onClick={() => insertTextAtCursor(' https://example.com ')}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <Link2 className="w-5 h-5 text-gray-600" />
        </button>
      </div>
      
      <div className="text-sm text-gray-500">
        {content.length}/{characterLimit}
      </div>
    </div>
  );
};

export default PostToolbar;
