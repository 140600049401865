// PostPreview.js
import React from 'react';
import { 
  MoreHorizontal, 
  ThumbsUp, 
  MessageSquare, 
  Repeat2, 
  Send, 
  Globe2,
  Calendar
} from 'lucide-react';

const PostPreview = ({ content, selectedImage, userProfile = {} }) => {
  const defaultProfile = {
    name: 'Your Name',
    title: 'Your Professional Title',
    avatar: null,
  };

  const profile = { ...defaultProfile, ...userProfile };

  // Function to format follower count
  const formatFollowers = (num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}k`;
    }
    return num;
  };

  return (
    <div className="w-[400px] h-min">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-4">
          {/* Header Section */}
          <div className="flex items-start justify-between">
            <div className="flex items-start flex-1">
              <div className="h-12 w-12 rounded-full bg-gray-200 flex-shrink-0 flex items-center justify-center text-gray-500 text-lg font-medium border border-gray-300">
                {profile.avatar ? (
                  <img 
                    src={profile.avatar} 
                    alt={profile.name} 
                    className="h-full w-full rounded-full object-cover"
                  />
                ) : (
                  profile.name.charAt(0)
                )}
              </div>
              <div className="ml-2 flex-1">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="font-semibold text-[14px] text-gray-900 hover:text-blue-600 hover:underline cursor-pointer">
                      {profile.name}
                    </h4>
                    <p className="text-[12px] text-gray-500 leading-tight">
                      {profile.title}
                    </p>
                    <div className="flex items-center text-[12px] text-gray-500 mt-0.5">
                      <span>1h</span>
                      <span className="mx-1">·</span>
                      <Globe2 className="w-3 h-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="p-2 hover:bg-gray-100 rounded-full -mr-2">
              <MoreHorizontal className="w-5 h-5 text-gray-600" />
            </button>
          </div>

          {/* Content Section */}
          <div className="mt-3">
            {content ? (
              <p className="text-[14px] text-gray-900 whitespace-pre-wrap">
                {content}
              </p>
            ) : (
              <div className="py-8 text-center text-gray-400 text-sm border border-dashed rounded-lg">
                Your post preview will appear here
              </div>
            )}
          </div>

          {/* Image Section */}
          {selectedImage && (
            <div className="mt-3 -mx-4">
              <img 
                src={selectedImage} 
                alt="Post content" 
                className="w-full object-cover max-h-[400px]"
              />
            </div>
          )}

          {/* Engagement Stats */}
          <div className="mt-3 pt-1">
            <div className="flex items-center justify-between text-[12px] text-gray-500 pb-2 border-b">
              <div className="flex items-center">
                <div className="flex -space-x-1">
                  <div className="w-4 h-4 rounded-full bg-blue-500 flex items-center justify-center">
                    <ThumbsUp className="w-2 h-2 text-white" />
                  </div>
                  <div className="w-4 h-4 rounded-full bg-red-500 flex items-center justify-center">
                    <span className="text-white text-[8px]">♥</span>
                  </div>
                </div>
                <span className="ml-2 hover:text-blue-600 hover:underline cursor-pointer">
                  {formatFollowers(267)}
                </span>
              </div>
              <div className="flex items-center space-x-4">
                <span className="hover:text-blue-600 hover:underline cursor-pointer">
                  {formatFollowers(24)} comments
                </span>
                <span className="hover:text-blue-600 hover:underline cursor-pointer">
                  {formatFollowers(12)} reposts
                </span>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-1 flex items-center justify-between">
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <ThumbsUp className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Like</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <MessageSquare className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Comment</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <Repeat2 className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Repost</span>
            </button>
            <button className="flex-1 flex items-center justify-center py-3 hover:bg-gray-100 rounded-lg group">
              <Send className="w-4 h-4 text-gray-600 group-hover:text-blue-600" />
              <span className="ml-2 text-[13px] font-medium text-gray-600 group-hover:text-blue-600">Send</span>
            </button>
          </div>
        </div>

        {/* Scheduled Status - Only show when post is scheduled */}
        {/* Uncomment and add logic to show this when needed
        <div className="px-4 py-2 bg-gray-50 border-t text-[13px] text-gray-600 flex items-center rounded-b-lg">
          <Calendar className="w-4 h-4 mr-2" />
          Scheduled for September 12, 2024 at 10:00 AM
        </div>
        */}
      </div>
    </div>
  );
};

export default PostPreview;