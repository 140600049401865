// PostActions.js
import React from 'react';
import { CalendarDays } from 'lucide-react';

const PostActions = ({ isLoading, onSaveDraft, onSchedule }) => {
  return (
    <div className="flex justify-end space-x-3 mt-6">
      <button
        onClick={onSaveDraft}
        disabled={isLoading}
        className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50 disabled:opacity-50 flex items-center"
      >
        {isLoading ? (
          <>
            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-700" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Saving...
          </>
        ) : (
          'Save as Draft'
        )}
      </button>
      <button
        onClick={onSchedule}
        disabled={isLoading}
        className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center"
      >
        <CalendarDays className="w-4 h-4 mr-2" />
        Schedule Now
      </button>
    </div>
  );
};

export default PostActions;