import React, { useRef, useState, useEffect } from 'react';
import { 
  Linkedin, 
  Instagram, 
  Clock,
  ChevronLeft,
  ChevronRight 
} from 'lucide-react';

const SocialMediaTabs = ({ channels, selectedChannel, onSelectChannel }) => {
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkForArrows = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        hasHorizontalScroll && container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkForArrows();
  }, [channels]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkForArrows, 100);
    }
  };

  const getIcon = (channel) => {
    switch (channel.toLowerCase()) {
      case 'linkedin':
        return <Linkedin className="h-5 w-5" />;
      case 'instagram':
        return <Instagram className="h-5 w-5" />;
      case 'tiktok':
        return (
          <svg 
            className="h-5 w-5" 
            viewBox="0 0 24 24" 
            fill="currentColor"
          >
            <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 112.31-4.64V9.32a6.32 6.32 0 00-1.37-.15A6.34 6.34 0 004 20.83a6.34 6.34 0 0010.86-4.43v-7a8.16 8.16 0 004.73 1.52v-3.4a4.85 4.85 0 01-1-.13z"/>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative">
      {showLeftArrow && (
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 -ml-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
        >
          <ChevronLeft className="h-6 w-6 text-gray-600" />
        </button>
      )}
      
      <div 
        className="relative overflow-x-hidden"
        style={{ margin: '0 20px' }}
      >
        <div
          ref={scrollContainerRef}
          className="flex space-x-4 overflow-x-auto scrollbar-hide"
          onScroll={checkForArrows}
        >
          {channels.map((channel) => (
            <button
              key={channel}
              onClick={() => onSelectChannel(channel)}
              className={`
                flex items-center px-6 py-3 rounded-lg transition-colors min-w-[200px]
                ${selectedChannel === channel
                  ? 'bg-white text-blue-600 shadow-md'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }
              `}
            >
              <span className="mr-3">{getIcon(channel)}</span>
              <span className="text-base font-medium">{channel}</span>
            </button>
          ))}
        </div>
      </div>

      {showRightArrow && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 -mr-4 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 z-10"
        >
          <ChevronRight className="h-6 w-6 text-gray-600" />
        </button>
      )}
    </div>
  );
};

// Add this CSS to your global styles or as a style tag
const ScrollbarHideStyles = `
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export default SocialMediaTabs;