import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './UserContext';
import AuthUI from './AuthUI';
import Dashboard from './Dashboard';
import HomePage from './HomePage';
import Channels from './Channels';
import Calendar from './Calendar';
import ComingSoon from './ComingSoon';
import PricingPage from './PricingPage';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth" element={<AuthUI />} />
          <Route 
            path="/dashboard" 
            element={
              localStorage.getItem('token') 
                ? <Dashboard /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route path="/calendar" 
          element={
              localStorage.getItem('token') 
                ? <Calendar /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/channels" 
            element={
              localStorage.getItem('token') 
                ? <Channels /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/analytics" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/ai-suggests" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route 
            path="/members" 
            element={
              localStorage.getItem('token') 
                ? <ComingSoon /> 
                : <Navigate to="/auth" replace />
            } 
          />
          <Route path="/upgrade" element={<PricingPage />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;