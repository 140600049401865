import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import {
  LogOut,
  Settings,
  User,
  Bell,
  Loader2,
  ExternalLink,
  UserCircle,
  Mail,
  Calendar
} from 'lucide-react';

const Header = () => {
  const { user, logout, loading, error } = useUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const getUserInitials = (user) => {
    if (!user || !user.email) return 'U';
    const words = user.email.split('@')[0].split('.');
    return words.map(word => word[0].toUpperCase()).join('').slice(0, 2);
  };

  return (
    <header className="bg-gray-50 border-b border-gray-200">
      <div className="flex justify-between items-center h-16">
        <div className="px-6">
          <Link to="/dashboard">
            <img 
              src="/socio-logo.png" 
              alt="SocioPot Logo" 
              className="h-[110px]"
            />
          </Link>
        </div>
        
        <div className="flex items-center px-4 space-x-4">
          {/* Notifications */}
          <button className="p-2 text-gray-600 hover:bg-gray-100 rounded-full relative">
            <Bell className="w-5 h-5" />
            <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
          </button>

          <Link 
            to="/upgrade" 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 text-sm font-medium transition-colors"
          >
            Upgrade
          </Link>

          {/* User Profile Dropdown */}
          <div className="relative" ref={dropdownRef}>
            <button 
              onClick={() => setShowDropdown(!showDropdown)}
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 transition-colors"
            >
              {loading ? (
                <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
              ) : (
                <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-medium">
                  {getUserInitials(user)}
                </div>
              )}
              <span className="text-sm font-medium text-gray-700 max-w-[150px] truncate">
                {loading ? 'Loading...' : user?.email || 'User'}
              </span>
            </button>

            {showDropdown && (
              <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-1 z-50">
                {/* Header */}
                <div className="px-4 py-3 border-b border-gray-100">
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span className="text-sm text-gray-500">Loading profile...</span>
                    </div>
                  ) : (
                    <>
                      <p className="text-sm font-medium text-gray-900">
                        {user?.name || user?.email}
                      </p>
                      {/* <p className="text-sm text-gray-500 mt-1">
                        {user?.email}
                      </p> */}
                    </>
                  )}
                </div>

                {/* Account Options */}
                {/* <div className="py-2">
                  <Link to="/profile" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <UserCircle className="w-4 h-4 mr-2" />
                    View Profile
                  </Link>
                  
                  <Link to="/settings/account" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <Settings className="w-4 h-4 mr-2" />
                    Account Settings
                  </Link>

                  {user?.role === 'admin' && (
                    <Link to="/admin" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      <ExternalLink className="w-4 h-4 mr-2" />
                      Admin Dashboard
                    </Link>
                  )}
                </div> */}

                {/* User Details */}
                {!loading && user && (
                  <div className="px-4 py-2 border-t border-gray-100">
                    <div className="text-xs text-gray-500">Account Details</div>
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center text-sm text-gray-600">
                        <Mail className="w-4 h-4 mr-2" />
                        {user.email}
                      </div>
                      <div className="flex items-center text-sm text-gray-600">
                        <Calendar className="w-4 h-4 mr-2" />
                        Joined {new Date(user.createdAt).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                )}

                {/* Logout */}
                <div className="border-t border-gray-100 mt-2">
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Sign out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;