// PostInput.js
import React from 'react';
import { Camera, X as XIcon } from 'lucide-react';

const PostInput = ({ content, onContentChange, selectedImage, onImageChange, onImageRemove }) => {
  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="mb-6">
      <div className="min-h-[200px] border rounded-lg p-4">
        <textarea
          placeholder="What would you like to share?"
          value={content}
          onChange={(e) => onContentChange(e.target.value)}
          className="w-full h-full min-h-[120px] resize-none focus:outline-none"
        />
        
        {selectedImage ? (
          <div className="relative group mt-4">
            <img 
              src={selectedImage} 
              alt="Upload preview" 
              className="max-w-full h-auto rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <button
                onClick={() => onImageRemove()} 
                className="p-2 bg-white rounded-full hover:bg-gray-100"
                title="Remove image"
              >
                <XIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        ) : (
          <div className="border-2 border-dashed rounded-lg p-8 mt-4">
            <div className="text-center">
              <Camera className="mx-auto h-12 w-12 text-gray-400" />
              <div className="mt-4">
                <label className="cursor-pointer">
                  <span className="text-blue-500 hover:text-blue-600">
                    Upload an image
                  </span>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostInput;