// services/waitlistService.js
import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs,
  serverTimestamp 
} from 'firebase/firestore';

// Initialize Firebase with your config
const firebaseConfig = {
  apiKey: "AIzaSyBqU6LnMTqEmEZwBTe09aY2f_SKmJjc60g",
  authDomain: "socioblink-8198b.firebaseapp.com",
  projectId: "socioblink-8198b",
  storageBucket: "socioblink-8198b.firebasestorage.app",
  messagingSenderId: "867117957844",
  appId: "1:867117957844:web:b12d665fc10e6455a1f141",
  measurementId: "G-TC91PQT4TH"
};

// Make sure we only initialize Firebase once
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  if (!/already exists/.test(error.message)) {
    console.error('Firebase initialization error:', error);
  }
}

const db = getFirestore(app);

class WaitlistService {
  async addToWaitlist(email, source) {
    try {
      console.log('Adding to waitlist:', { email, source }); // Debug log

      // Basic email validation
      if (!email || !email.includes('@')) {
        console.log('Invalid email format'); // Debug log
        return {
          success: false,
          message: 'Please enter a valid email address'
        };
      }

      // Check for existing email
      const waitlistRef = collection(db, 'waitlist');
      const emailQuery = query(waitlistRef, where('email', '==', email));
      
      try {
        const querySnapshot = await getDocs(emailQuery);
        if (!querySnapshot.empty) {
          console.log('Email already exists'); // Debug log
          return {
            success: false,
            message: 'This email is already on the waitlist'
          };
        }
      } catch (queryError) {
        console.error('Error checking existing email:', queryError);
        throw queryError;
      }

      // Add new entry
      const docRef = await addDoc(waitlistRef, {
        email,
        source,
        timestamp: serverTimestamp(),
        status: 'active',
        metadata: {
          userAgent: window.navigator.userAgent,
          platform: window.navigator.platform,
          referrer: document.referrer
        }
      });

      console.log('Successfully added to waitlist:', docRef.id); // Debug log

      return {
        success: true,
        message: 'Successfully added to waitlist',
        id: docRef.id
      };

    } catch (error) {
      console.error('Error in addToWaitlist:', error);
      throw new Error('Failed to add to waitlist: ' + error.message);
    }
  }

  async getWaitlistCount() {
    try {
      const waitlistRef = collection(db, 'waitlist');
      const activeQuery = query(waitlistRef, where('status', '==', 'active'));
      const snapshot = await getDocs(activeQuery);
      return snapshot.size;
    } catch (error) {
      console.error('Error getting waitlist count:', error);
      return 0;
    }
  }
}

export const waitlistService = new WaitlistService();