import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import SocialMediaTabs from './components/SocialMediaTabs';
import PostList from './components/PostList';
import CreatePost from './components/CreatePost';
import { PLATFORMS, PLATFORM_LABELS } from './Constants';
import { useUser } from './UserContext';
import { Alert, AlertDescription } from './components/Alert';

const Dashboard = () => {
  const { user } = useUser();
  const [connectedChannels, setConnectedChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [postType, setPostType] = useState('scheduled');
  const [showCreatePost, setShowCreatePost] = useState(false);
  const navigate = useNavigate();

  // Function to get connected channels from user's social accounts
  const getConnectedChannels = () => {
    const channels = [];
    
    // Check LinkedIn connection
    if (user?.socialAccounts?.linkedin?.isConnected) {
      channels.push('LinkedIn');
    }
    
    // Check Instagram connection
    if (user?.socialAccounts?.instagram?.isConnected) {
      channels.push('Instagram');
    }
    
    // Check TikTok connection
    if (user?.socialAccounts?.tiktok?.isConnected) {
      channels.push('TikTok');
    }
    
    return channels;
  };

  useEffect(() => {
    const fetchConnectedChannels = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Get connected channels based on user's social accounts
        const channels = getConnectedChannels();
        
        if (channels.length === 0) {
          setError('No connected social media accounts found. Please connect your accounts in the Channels section.');
        }
        
        setConnectedChannels(channels);
        
        // Set the first connected channel as selected if available
        if (channels.length > 0 && !selectedChannel) {
          setSelectedChannel(channels[0]);
        } else if (channels.length === 0) {
          setSelectedChannel(null);
        }

      } catch (error) {
        console.error('Error fetching channels:', error);
        setError('Failed to fetch connected channels. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchConnectedChannels();
  }, [user]); // Depend on user to update when connections change

  useEffect(() => {
    if (selectedChannel) {
      setPostType('scheduled');
      fetchPosts(selectedChannel, 'scheduled');
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedChannel && postType) {
      fetchPosts(selectedChannel, postType);
    }
  }, [postType]);

  const fetchPosts = async (channel, type) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts?platform=${channel.toLowerCase()}&status=${type}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch posts');

      const data = await response.json();
      setPosts(data.posts || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChannelSelect = (channel) => {
    setSelectedChannel(channel);
  };

  const handlePostTypeChange = (type) => {
    setPostType(type);
  };

  const handleCreatePost = () => {
    setShowCreatePost(true);
  };

  const getConnectedAccountsForCreatePost = () => {
    return Object.entries(PLATFORMS)
      .filter(([key]) => user?.socialAccounts?.[key.toLowerCase()]?.isConnected)
      .map(([, value]) => value);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100 relative">
          {!showCreatePost ? (
            <div className="p-6">
              <h3 className="text-2xl font-medium text-gray-800 mb-6">Home</h3>
              
              {error && (
                <Alert variant="destructive" className="mb-6">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              {connectedChannels.length === 0 ? (
                <div className="text-center py-12">
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    No Connected Accounts
                  </h3>
                  <p className="text-gray-500 mb-6">
                    Connect your social media accounts to start managing your content.
                  </p>
                  <button
                    onClick={() => navigate('/channels')}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Connect Accounts
                  </button>
                </div>
              ) : (
                <>
                  <div className="mb-6">
                    <SocialMediaTabs 
                      channels={connectedChannels} 
                      selectedChannel={selectedChannel} 
                      onSelectChannel={handleChannelSelect} 
                    />
                  </div>
                  <PostList 
                    posts={posts} 
                    postType={postType}
                    onPostTypeChange={handlePostTypeChange}
                    onCreatePost={handleCreatePost}
                    isLoading={isLoading}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="h-full overflow-auto">
              <div className="sticky top-0 z-10 bg-gray-100 border-b border-gray-200">
                <div className="max-w-6xl mx-auto px-6 py-4">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => setShowCreatePost(false)}
                      className="flex items-center text-gray-600 hover:text-gray-900 font-medium"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-5 w-5 mr-2" 
                        viewBox="0 0 20 20" 
                        fill="currentColor"
                      >
                        <path 
                          fillRule="evenodd" 
                          d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
                          clipRule="evenodd" 
                        />
                      </svg>
                      Back to Posts
                    </button>
                    <div className="text-sm text-gray-500">
                      All changes saved
                    </div>
                  </div>
                </div>
              </div>

              <div className="max-w-6xl mx-auto px-6 py-6">
                <CreatePost
                  connectedAccounts={getConnectedAccountsForCreatePost()}
                  onClose={() => setShowCreatePost(false)}
                />
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;