import React, { useState, useEffect } from 'react';
import { 
  Pencil, 
  Clock, 
  Trash2,
  CalendarClock,
  Send,
  FileEdit,
  PlusCircle,
  Plus,
  Loader2,
  AlertCircle,
  CheckCircle2,
  Linkedin,
  Instagram,
  Globe,
  Image as ImageIcon
} from 'lucide-react';
import { Alert, AlertDescription } from './Alert';
import EditPostDialog from './EditPostDialog';

// Platform icon mapping component
const PlatformIcon = ({ platform }) => {
  const iconMap = {
    linkedin: Linkedin,
    instagram: Instagram,
    tiktok: ({ className }) => (
      <svg 
        className={className}
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 112.31-4.64V9.32a6.32 6.32 0 00-1.37-.15A6.34 6.34 0 004 20.83a6.34 6.34 0 0010.86-4.43v-7a8.16 8.16 0 004.73 1.52v-3.4a4.85 4.85 0 01-1-.13z"/>
      </svg>
    )
  };

  const Icon = iconMap[platform.toLowerCase()] || Globe;
  return <Icon className="w-5 h-5" />;
};

// New component for compact media preview
const MediaPreview = ({ media, onRetry }) => {
  const [error, setError] = useState(false);

  if (!media) return null;

  const handleError = () => {
    setError(true);
    if (onRetry) onRetry();
  };

  if (error) {
    return (
      <div className="w-32 h-32 flex items-center justify-center bg-gray-100 rounded-lg flex-shrink-0">
        <div className="text-center">
          <ImageIcon className="w-6 h-6 text-gray-400 mx-auto mb-1" />
          <p className="text-xs text-gray-500">Failed to load</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative group w-32 h-32 flex-shrink-0">
      <img 
        src={media}
        alt="Post content" 
        className="w-full h-full rounded-lg object-cover"
        onError={handleError}
      />
      <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-lg">
        <a 
          href={media} 
          target="_blank" 
          rel="noopener noreferrer"
          className="p-2 bg-white text-gray-900 rounded-lg hover:bg-gray-100 transition-colors text-xs font-medium"
        >
          View Full
        </a>
      </div>
    </div>
  );
};


const PostList = ({ 
  onPostTypeChange, 
  onCreatePost, 
  isLoading: parentLoading,
  connectedAccounts = []
}) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [postType, setPostType] = useState('scheduled');
  const [editingPost, setEditingPost] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Platform styles
  const platformStyles = {
    linkedin: {
      bg: 'bg-[#0077B5]/10',
      text: 'text-[#0077B5]'
    },
    instagram: {
      bg: 'bg-[#E4405F]/10',
      text: 'text-[#E4405F]'
    },
    tiktok: {
      bg: 'bg-[#000000]/10',
      text: 'text-[#000000]'
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [postType]);

  const fetchPosts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts?status=${postType}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to fetch posts');
      }

      const data = await response.json();
      setPosts(data.posts);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError(err.message || 'Failed to load posts');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostDelete = async (postId) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    setDeleteLoading(postId);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete post');
      }

      setPosts(posts.filter(post => post.id !== postId));
      setSuccessMessage('Post deleted successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      console.error('Error deleting post:', err);
      setError(err.message || 'Failed to delete post');
    } finally {
      setDeleteLoading(null);
    }
  };

  const handlePostEdit = (post) => {
    // Normalize the post content structure
    const normalizedPost = {
      ...post,
      content: {
        text: typeof post.content === 'object' ? post.content.text : post.content,
        media: post.content?.media || post.media || null,
        firstComment: post.content?.firstComment || post.firstComment || ''
      }
    };
    setEditingPost(normalizedPost);
  };

  const handlePostUpdate = (updatedPost) => {
    setPosts(posts.map(post => 
      post.id === updatedPost.id ? updatedPost : post
    ));
    setSuccessMessage('Post updated successfully');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return date.toLocaleDateString('en-US', options);
  };

  const renderPost = (post) => (
    <div key={post.id} className="bg-white rounded-lg shadow-sm mb-4 p-4">
      <div className="flex flex-col gap-4">
        {/* Platform badges and metadata */}
        <div className="flex items-start justify-between">
          <div className="flex flex-wrap gap-2">
            {post.platforms.map(platform => {
              const styles = platformStyles[platform.toLowerCase()] || {
                bg: 'bg-gray-100',
                text: 'text-gray-600'
              };
              
              return (
                <span 
                  key={platform}
                  className={`flex items-center gap-1.5 px-3 py-1 rounded-full text-sm ${styles.bg} ${styles.text}`}
                >
                  <PlatformIcon platform={platform} />
                  {platform}
                </span>
              );
            })}
          </div>
  
          <div className="text-sm text-gray-500 space-y-1 text-right">
            <div className="flex items-center justify-end gap-1">
              <Clock className="w-4 h-4" />
              Created {formatDate(post.createdAt)}
            </div>
            
            {post.status === 'scheduled' && (
              <div className="flex items-center justify-end gap-1 text-blue-600">
                <CalendarClock className="w-4 h-4" />
                Scheduled for {formatDate(post.scheduledAt)}
              </div>
            )}
            
            {post.status === 'posted' && (
              <div className="flex items-center justify-end gap-1 text-green-600">
                <CheckCircle2 className="w-4 h-4" />
                Posted {formatDate(post.publishedAt)}
              </div>
            )}
          </div>
        </div>
  
        {/* Post content - Updated to handle nested content structure */}
        <div className="border rounded-lg p-4">
          <p className="text-gray-800 whitespace-pre-wrap">
            {typeof post.content === 'object' ? post.content.text : post.content}
          </p>
          
          {/* Media preview - Updated to handle nested content structure */}
          {(post.content?.media || post.media) && (
            <MediaPreview 
              media={post.content?.media || post.media}
              onRetry={() => {
                console.log('Retrying media load for post:', post.id);
              }}
            />
          )}
  
          {/* First comment - Updated to handle nested content structure */}
          {(post.content?.firstComment || post.firstComment) && (
            <div className="mt-4 pt-4 border-t">
              <p className="text-sm text-gray-600">
                <span className="font-medium">First comment:</span>{' '}
                {post.content?.firstComment || post.firstComment}
              </p>
            </div>
          )}
        </div>
  
        {/* Action buttons */}
        <div className="flex justify-end gap-2">
          {post.status !== 'posted' && (
            <button 
              onClick={() => handlePostEdit(post)}
              className="p-2 text-gray-500 hover:text-blue-600 rounded-full hover:bg-blue-50 disabled:opacity-50"
              title="Edit post"
              disabled={deleteLoading === post.id}
            >
              <Pencil size={18} />
            </button>
          )}
          <button 
            onClick={() => handlePostDelete(post.id)}
            className="p-2 text-gray-500 hover:text-red-600 rounded-full hover:bg-red-50 disabled:opacity-50"
            title="Delete post"
            disabled={deleteLoading === post.id}
          >
            {deleteLoading === post.id ? (
              <Loader2 size={18} className="animate-spin" />
            ) : (
              <Trash2 size={18} />
            )}
          </button>
        </div>
      </div>
    </div>
  );

  const typeButtonClass = (type) => 
    `font-medium px-4 py-2 ${postType === type ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`;

  const EmptyState = ({ type }) => {
    const emptyStates = {
      scheduled: {
        icon: CalendarClock,
        title: "No scheduled posts yet",
        description: "Plan ahead by scheduling your posts. Your scheduled posts will appear here.",
        actionText: "Schedule a post",
      },
      sent: {
        icon: Send,
        title: "No posts have been sent yet",
        description: "Once you start publishing posts, they'll appear here for your reference.",
        actionText: "Create your first post",
      },
      drafts: {
        icon: FileEdit,
        title: "No drafts yet",
        description: "Save your post ideas as drafts and come back to them later.",
        actionText: "Create new draft",
      }
    };

    const { icon: Icon, title, description, actionText } = emptyStates[type];

    return (
      <div className="text-center py-12 px-4 bg-white rounded-lg shadow-sm">
        <div className="flex justify-center mb-4">
          <Icon className="h-12 w-12 text-gray-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-500 mb-6 max-w-sm mx-auto">{description}</p>
        <button 
          onClick={onCreatePost}
          className="inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
        >
          <PlusCircle className="h-5 w-5 mr-2" />
          {actionText}
        </button>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading || parentLoading) {
      return (
        <div className="flex justify-center items-center h-64">
          <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
        </div>
      );
    }

    if (error) {
      return (
        <Alert variant="destructive" className="mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      );
    }

    if (posts.length === 0) {
      return <EmptyState type={postType} />;
    }

    return posts.map(post => renderPost(post));
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-200">
        <div className="flex space-x-6">
          <button 
            className={typeButtonClass('scheduled')}
            onClick={() => setPostType('scheduled')}
          >
            Scheduled
          </button>
          <button 
            className={typeButtonClass('sent')}
            onClick={() => setPostType('sent')}
          >
            Sent
          </button>
          <button 
            className={typeButtonClass('drafts')}
            onClick={() => setPostType('drafts')}
          >
            Drafts
          </button>
        </div>
        <button
          onClick={onCreatePost}
          className="inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-sm font-medium transition-colors"
        >
          <Plus className="h-5 w-5 mr-1.5" />
          Create Post
        </button>
      </div>

      {successMessage && (
        <Alert className="mb-4">
          <CheckCircle2 className="h-4 w-4" />
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}
      
      {renderContent()}

      {editingPost && (
        <EditPostDialog
          post={editingPost}
          isOpen={!!editingPost}
          onClose={() => setEditingPost(null)}
          onUpdate={handlePostUpdate}
          connectedAccounts={connectedAccounts}
        />
      )}
    </div>
  );
};

export default PostList;