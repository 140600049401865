import React, { useState, useEffect } from 'react';
import { Shield, Zap, Rocket, ChevronRight, Star, Lock, AlertCircle } from 'lucide-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { waitlistService } from './services/waitlistService';
import { Alert, AlertDescription } from './components/Alert';

const PricingPage = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const result = await waitlistService.addToWaitlist(email, 'pricing');
      
      if (result.success) {
        setIsSubscribed(true);
        setEmail('');
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to join waitlist. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const comingSoonFeatures = [
    'Platform Algorithm Insights',
    'AI-powered content generation',
    'Advanced analytics and reporting',
    'Team collaboration tools',
    'Custom branded content',
    'Custom integrations',
    '24/7 Priority support'
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100 p-6">
          <div className="max-w-5xl mx-auto">
            {/* Header Section */}
            <div className="text-center mb-12">
              <div className="inline-flex items-center px-4 py-2 bg-blue-100 text-blue-700 rounded-full text-sm font-medium mb-4">
                <Star className="w-4 h-4 mr-2" />
                Coming Soon
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Upgrade Your Social Media Game
              </h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                We're working hard to bring you powerful new features. 
                Subscribe to be notified when our premium plans launch.
              </p>
            </div>

            {/* Feature Preview */}
            <div className="bg-white rounded-2xl shadow-lg p-8 mb-12">
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                    Premium Features Coming Soon
                  </h2>
                  <div className="space-y-4">
                    {comingSoonFeatures.map((feature, index) => (
                      <div key={index} className="flex items-center text-gray-600">
                        <Lock className="w-5 h-5 text-blue-500 mr-3" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <div className="text-center">
                    <Rocket className="w-24 h-24 text-blue-500 mx-auto mb-4" />
                    <p className="text-gray-600">
                      Our team is working hard to bring these features to life
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Newsletter Signup */}
            <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl shadow-lg p-8 text-white">
              <div className="max-w-2xl mx-auto text-center">
                {error && (
                  <Alert variant="destructive" className="mb-6">
                    <AlertCircle className="w-4 h-4" />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                {isSubscribed ? (
                  <div className="space-y-4">
                    <div className="flex justify-center">
                      <div className="bg-white text-blue-600 rounded-full p-3">
                        <Star className="w-8 h-8" />
                      </div>
                    </div>
                    <h3 className="text-xl font-semibold">You're on the list!</h3>
                    <p>We'll notify you when premium features are available.</p>
                  </div>
                ) : (
                  <>
                    <h3 className="text-2xl font-semibold mb-4">
                      Be the First to Know
                    </h3>
                    <p className="mb-6 opacity-90">
                      Subscribe to our newsletter and get early access to premium features 
                      and special launch pricing.
                    </p>
                    <form onSubmit={handleSubscribe} className="flex gap-4 max-w-md mx-auto">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="flex-1 px-4 py-3 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                        disabled={isLoading}
                      />
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="px-6 py-3 bg-white text-blue-600 rounded-lg font-medium hover:bg-gray-100 transition-colors flex items-center disabled:opacity-50"
                      >
                        {isLoading ? 'Processing...' : (
                          <>
                            Notify Me
                            <ChevronRight className="w-4 h-4 ml-2" />
                          </>
                        )}
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>

            {/* Current Plan */}
            <div className="mt-12 text-center text-gray-600">
              <p>
                You're currently on our Free Plan. 
                Premium features will be available soon.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PricingPage;