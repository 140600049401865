import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, AlertDescription } from './components/Alert';
import { useUser } from './UserContext';
import { CheckCircle2, AlertCircle } from 'lucide-react';

const AuthUI = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingAuth, setIsProcessingAuth] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useUser();
  const location = useLocation();

  useEffect(() => {
    // If user is already logged in, redirect to dashboard
    if (user) {
      // navigate('/dashboard');
      window.location.href = '/dashboard';
      return;
    }

    // If we're already processing auth, don't do anything
    if (isProcessingAuth) {
      return;
    }

    const handleAuthentication = async () => {
      try {
        setIsProcessingAuth(true);
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        
        if (token) {
          localStorage.setItem('token', token);
          await login(token);
          window.history.replaceState({}, document.title, '/auth');
          // navigate('/dashboard');
          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setError('Authentication failed. Please try again.');
      } finally {
        setIsProcessingAuth(false);
      }
    };

    handleAuthentication();
  }, [location, navigate, login, user, isProcessingAuth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    const url = isLogin ? '/login' : '/signup';

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      
      const data = await response.json();

      if (response.ok) {
        if (isLogin) {
          // Handle successful login
          login(data.token, { email });
          navigate('/dashboard');
        } else {
          // Handle successful signup
          setSuccess('Account created successfully! Please sign in.');
          setEmail('');
          setPassword('');
          // Switch to login form after short delay
          setTimeout(() => {
            setIsLogin(true);
            setSuccess('');
          }, 2000);
        }
      } else {
        // Handle specific error cases
        if (isLogin) {
          setError(data.error || 'Invalid email or password');
        } else {
          if (data.error?.includes('exists')) {
            setError('An account with this email already exists');
          } else {
            setError(data.error || 'Failed to create account');
          }
        }
      }
    } catch (error) {
      setError(isLogin ? 'Failed to sign in' : 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (isProcessingAuth) return;

  //   setError('');
  //   const url = isLogin ? '/login' : '/signup';
    
  //   try {
  //     setIsProcessingAuth(true);
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ email, password }),
  //     });
      
  //     const data = await response.json();
      
  //     if (response.ok) {
  //       localStorage.setItem('token', data.token);
  //       await login(data.token, { email });
  //       // navigate('/dashboard');
  //       window.location.href = '/dashboard';
  //     } else {
  //       setError(data.error || 'An error occurred');
  //     }
  //   } catch (error) {
  //     console.error('Login error:', error);
  //     setError('Network error');
  //   } finally {
  //     setIsProcessingAuth(false);
  //   }
  // };

  const handleGoogleAuth = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isLogin ? 'Sign in to your account' : 'Create a new account'}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                    {/* Success Message Alert */}
                    {success && (
            <Alert className="mb-6">
              <CheckCircle2 className="h-4 w-4 text-green-600" />
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}

          {/* Error Message Alert */}
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isLogin ? 'Sign in' : 'Sign up'}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleGoogleAuth}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z" />
                </svg>
                Sign in with Google
              </button>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={() => setIsLogin(!isLogin)}
              className="w-full text-center text-sm text-indigo-600 hover:text-indigo-500"
            >
              {isLogin ? 'Need an account? Sign up' : 'Already have an account? Sign in'}
            </button>
          </div>

          {/* {error && (
            <Alert className="mt-6" variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AuthUI;