import React from 'react';
import { 
  PLATFORM_LABELS, 
  PLATFORM_ICONS, 
  PLATFORM_COLORS,
  CONNECTION_STATUS 
} from '../Constants';

const AccountSelector = ({ 
  accounts, 
  selectedAccounts, 
  onAccountToggle,
  className = ''
}) => {
  return (
    <div className={`mb-6 ${className}`}>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Post to
      </label>
      <div className="flex flex-wrap gap-3">
        {accounts.map(({ id, isConnected }) => {
          const Icon = PLATFORM_ICONS[id];
          const colors = PLATFORM_COLORS[id];
          
          return (
            <button
              key={id}
              onClick={() => onAccountToggle(id)}
              disabled={!isConnected}
              className={`
                flex items-center px-4 py-2 rounded-lg border transition-colors
                ${selectedAccounts.includes(id) 
                  ? `${colors.bg} text-white`
                  : 'bg-white border-gray-200 hover:bg-gray-50'}
                ${!isConnected && 'opacity-50 cursor-not-allowed'}
              `}
            >
              <Icon className={`w-5 h-5 mr-2 ${selectedAccounts.includes(id) ? 'text-white' : colors.text}`} />
              <span>{PLATFORM_LABELS[id]}</span>
              {!isConnected && (
                <span className="ml-2 text-xs text-gray-500">
                  ({CONNECTION_STATUS.NOT_CONNECTED})
                </span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default AccountSelector;