// Constants.js
import { Linkedin, Instagram, Camera } from 'lucide-react';

export const PLATFORMS = {
  LINKEDIN: 'linkedin',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok'
};

export const PLATFORM_LABELS = {
  [PLATFORMS.LINKEDIN]: 'LinkedIn',
  [PLATFORMS.INSTAGRAM]: 'Instagram',
  [PLATFORMS.TIKTOK]: 'TikTok'
};

export const PLATFORM_ICONS = {
  [PLATFORMS.LINKEDIN]: Linkedin,
  [PLATFORMS.INSTAGRAM]: Instagram,
  [PLATFORMS.TIKTOK]: Camera // Using Camera icon for TikTok as a placeholder
};

// Platform-specific colors (optional, for styling)
export const PLATFORM_COLORS = {
  [PLATFORMS.LINKEDIN]: {
    bg: 'bg-[#0A66C2]',
    hover: 'hover:bg-[#004182]',
    text: 'text-[#0A66C2]'
  },
  [PLATFORMS.INSTAGRAM]: {
    bg: 'bg-[#E4405F]',
    hover: 'hover:bg-[#D32645]',
    text: 'text-[#E4405F]'
  },
  [PLATFORMS.TIKTOK]: {
    bg: 'bg-[#000000]',
    hover: 'hover:bg-[#1a1a1a]',
    text: 'text-[#000000]'
  }
};

// Platform connection status messages
export const CONNECTION_STATUS = {
  CONNECTED: 'Connected',
  NOT_CONNECTED: 'Not connected',
  CONNECTING: 'Connecting...',
  ERROR: 'Connection error'
};

// Post status constants
export const POST_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  POSTED: 'posted',
  FAILED: 'failed'
};

// Default schedule settings
export const SCHEDULE_SETTINGS = {
  MIN_SCHEDULE_DELAY: 5, // minutes
  DEFAULT_TIME_SLOT: '09:00',
  TIME_ZONE: Intl.DateTimeFormat().resolvedOptions().timeZone
};

// Validation constants
export const VALIDATION = {
  MAX_CONTENT_LENGTH: 3000,
  MAX_IMAGE_SIZE: 5 * 1024 * 1024, // 5MB
  SUPPORTED_IMAGE_TYPES: ['image/jpeg', 'image/png', 'image/gif']
};