import React, { useState, useEffect } from 'react';
import { 
  Linkedin, 
  Instagram, 
  CircleUserRound,
  LinkIcon,
  AlertCircle,
  CheckCircle2,
  Loader2,
  Youtube,
  Facebook,
  Share2,
  Building2,
  BookMarked
} from 'lucide-react';
import { Alert, AlertDescription } from './components/Alert';
import { useUser } from './UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const ChannelsContent = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [linkedinStatus, setLinkedinStatus] = useState({ loading: false, success: false });

  // Extended connected accounts state
  const [connectedAccounts, setConnectedAccounts] = useState({
    linkedin: user?.socialAccounts?.linkedin?.isConnected || false,
    linkedinPage: false,
    instagram: false,
    tiktok: false,
    youtube: false,
    facebook: false,
    bluesky: false,
    pinterest: false
  });

  // Update connection status when user data changes
  useEffect(() => {
    setConnectedAccounts(prev => ({
      ...prev,
      linkedin: user?.socialAccounts?.linkedin?.isConnected || false
    }));
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const linkedinStatus = params.get('linkedin');
    const error = params.get('error');
  
    if (linkedinStatus === 'success') {
      setLinkedinStatus({ loading: false, success: true });
      setConnectedAccounts(prev => ({
        ...prev,
        linkedin: true
      }));
      navigate('/channels', { replace: true });
    } else if (error) {
      setError(error.replace(/_/g, ' '));
      setLinkedinStatus({ loading: false, success: false });
    }
    setLoading(false);
  }, [location.search, navigate]);

  const handleLinkedinConnect = async () => {
    try {
      setLinkedinStatus({ loading: true, success: false });
      setError(null);
  
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Token validation failed');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        throw new Error('Please log in again');
      }
  
      const baseUrl = `${process.env.REACT_APP_API_URL}/auth/linkedin`;
      const url = new URL(baseUrl);
      url.searchParams.append('token', token);
      
      console.log('Redirecting to LinkedIn auth:', url.toString());
      window.location.href = url.toString();
    } catch (error) {
      console.error('Error initiating LinkedIn connection:', error);
      setError(error.message);
      setLinkedinStatus({ loading: false, success: false });
    }
  };

  const ConnectButton = ({ platform, connected, onClick, loading, isConnecting }) => {
    let Icon;
    let bgColor;
    let hoverBg;
    
    switch (platform.toLowerCase()) {
      case 'linkedin':
      case 'linkedinpage':
        Icon = platform.toLowerCase() === 'linkedin' ? Linkedin : Building2;
        bgColor = connected ? 'bg-[#0A66C2]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#004182]' : 'hover:bg-gray-50';
        break;
      case 'instagram':
        Icon = Instagram;
        bgColor = connected ? 'bg-[#E4405F]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#D32645]' : 'hover:bg-gray-50';
        break;
      case 'youtube':
        Icon = Youtube;
        bgColor = connected ? 'bg-[#FF0000]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#CC0000]' : 'hover:bg-gray-50';
        break;
      case 'facebook':
        Icon = Facebook;
        bgColor = connected ? 'bg-[#1877F2]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#0C63D4]' : 'hover:bg-gray-50';
        break;
      case 'bluesky':
        Icon = Share2;
        bgColor = connected ? 'bg-[#0560FF]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#0448CC]' : 'hover:bg-gray-50';
        break;
      case 'pinterest':
        Icon = BookMarked;
        bgColor = connected ? 'bg-[#E60023]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#B3001B]' : 'hover:bg-gray-50';
        break;
      case 'tiktok':
        Icon = CircleUserRound;
        bgColor = connected ? 'bg-[#000000]' : 'bg-white';
        hoverBg = connected ? 'hover:bg-[#1a1a1a]' : 'hover:bg-gray-50';
        break;
      default:
        Icon = LinkIcon;
        bgColor = 'bg-white';
        hoverBg = 'hover:bg-gray-50';
    }

    return (
      <button
        onClick={onClick}
        disabled={loading || isConnecting || connected}
        className={`
          ${bgColor} ${hoverBg}
          ${connected ? 'text-white' : 'text-gray-700 border border-gray-200'}
          w-full px-4 py-3 rounded-lg transition-colors duration-200
          flex items-center justify-center space-x-2
          ${(loading || isConnecting) ? 'opacity-75 cursor-not-allowed' : ''}
          ${connected ? 'cursor-default' : ''}
        `}
      >
        {loading || isConnecting ? (
          <>
            <Loader2 className="w-5 h-5 animate-spin mr-2" />
            <span>Connecting...</span>
          </>
        ) : (
          <>
            <Icon className="w-5 h-5" />
            <span className="font-medium">
              {connected ? 'Connected' : `Connect ${platform}`}
            </span>
            {connected && <CheckCircle2 className="w-5 h-5 ml-2" />}
          </>
        )}
      </button>
    );
  };

  const PlatformCard = ({ title, description, platform }) => {
    const isConnected = connectedAccounts[platform.toLowerCase()];
    const isConnecting = platform.toLowerCase() === 'linkedin' && linkedinStatus.loading;
    
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
          {isConnected && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Connected
            </span>
          )}
        </div>
        
        <ConnectButton
          platform={platform}
          connected={isConnected}
          onClick={platform.toLowerCase() === 'linkedin' ? handleLinkedinConnect : undefined}
          loading={loading}
          isConnecting={isConnecting}
        />
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h2 className="text-2xl font-medium text-gray-900">Connected Channels</h2>
        <p className="mt-1 text-sm text-gray-500">
          Connect your social media accounts to start managing your content in one place.
        </p>
      </div>

      {error && (
        <Alert className="mb-6" variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {linkedinStatus.success && (
        <Alert className="mb-6">
          <CheckCircle2 className="h-4 w-4" />
          <AlertDescription>Successfully connected to LinkedIn!</AlertDescription>
        </Alert>
      )}

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <PlatformCard
          title="LinkedIn Profile"
          description="Share updates and articles with your professional network."
          platform="LinkedIn"
        />
        
        <PlatformCard
          title="LinkedIn Page"
          description="Manage and grow your company's LinkedIn presence."
          platform="LinkedInPage"
        />
        
        <PlatformCard
          title="Instagram"
          description="Share photos, stories, and engage with your followers."
          platform="Instagram"
        />
        
        <PlatformCard
          title="Facebook"
          description="Connect with your audience through posts, photos, and videos."
          platform="Facebook"
        />
        
        <PlatformCard
          title="YouTube"
          description="Share and manage your video content across channels."
          platform="YouTube"
        />
        
        <PlatformCard
          title="TikTok"
          description="Create and share short-form videos with your audience."
          platform="TikTok"
        />
        
        <PlatformCard
          title="BlueSky"
          description="Join the decentralized social network revolution."
          platform="BlueSky"
        />
        
        <PlatformCard
          title="Pinterest"
          description="Share visual content and inspire your audience."
          platform="Pinterest"
        />
      </div>
    </div>
  );
};

// Wrapper component that includes the dashboard layout
const Channels = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100">
          <ChannelsContent />
        </main>
      </div>
    </div>
  );
};

export default Channels;