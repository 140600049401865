import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth, isSameDay, addMonths, subMonths, parseISO, isValid } from 'date-fns';
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon, Clock, LayoutGrid, Menu } from 'lucide-react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Alert, AlertDescription } from './components/Alert';

const PostCard = ({ post }) => {
  // Helper function to safely parse dates
  const formatPostDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return isValid(date) ? format(date, 'h:mm a') : '';
    } catch (error) {
      console.error('Error parsing date:', error);
      return '';
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      scheduled: 'bg-blue-100 text-blue-800',
      draft: 'bg-gray-100 text-gray-800',
      sent: 'bg-green-100 text-green-800'
    };
    return colors[status] || colors.draft;
  };

  return (
    <div className="p-2 mb-1 rounded-md bg-white shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
      <div className="flex items-start justify-between">
        <div className="flex-1 min-w-0">
          <p className="text-xs font-medium text-gray-900 truncate">
            {post.content.substring(0, 50)}...
          </p>
          <div className="flex items-center mt-1">
            <Clock className="w-3 h-3 text-gray-400 mr-1" />
            <span className="text-xs text-gray-500">
              {formatPostDate(post.scheduledAt || post.createdAt)}
            </span>
          </div>
        </div>
        <span className={`text-xs px-2 py-1 rounded-full ml-2 whitespace-nowrap ${getStatusColor(post.status)}`}>
          {post.status}
        </span>
      </div>
    </div>
  );
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month'); // 'month' or 'week'
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPosts = async (start, end) => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No auth token found');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch posts');

      const data = await response.json();
      setPosts(data.posts || []);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    fetchPosts(start, end);
  }, [currentDate]);

  const getPostsForDay = (day) => {
    return posts.filter(post => {
      try {
        const postDate = parseISO(post.scheduledAt || post.createdAt);
        return isValid(postDate) && isSameDay(postDate, day);
      } catch (error) {
        console.error('Error parsing post date:', error);
        return false;
      }
    });
  };

  const renderMonthView = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);
    const calendarStart = startOfWeek(monthStart);
    const calendarEnd = endOfWeek(monthEnd);
    const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

    return (
      <div className="grid grid-cols-7 gap-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="p-2 text-center text-sm font-medium text-gray-600">
            {day}
          </div>
        ))}
        {days.map((day, idx) => {
          const dayPosts = getPostsForDay(day);
          const isCurrentMonth = isSameMonth(day, currentDate);

          return (
            <div
              key={idx}
              className={`min-h-[120px] p-2 border rounded-lg ${
                isCurrentMonth ? 'bg-white' : 'bg-gray-50'
              }`}
            >
              <div className={`text-sm font-medium mb-1 ${
                isCurrentMonth ? 'text-gray-900' : 'text-gray-400'
              }`}>
                {format(day, 'd')}
              </div>
              <div className="space-y-1 overflow-y-auto max-h-[100px]">
                {dayPosts.map(post => (
                  <PostCard key={post._id} post={post} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderWeekView = () => {
    const weekStart = startOfWeek(currentDate);
    const weekEnd = endOfWeek(currentDate);
    const days = eachDayOfInterval({ start: weekStart, end: weekEnd });

    return (
      <div className="grid grid-cols-7 gap-2">
        {days.map((day, idx) => (
          <div key={idx} className="min-h-[600px]">
            <div className="text-center p-2 bg-gray-50 rounded-t-lg">
              <div className="font-medium text-gray-900">
                {format(day, 'EEE')}
              </div>
              <div className="text-sm text-gray-500">
                {format(day, 'MMM d')}
              </div>
            </div>
            <div className="border rounded-b-lg bg-white p-2 min-h-[calc(100%-4rem)] space-y-2">
              {getPostsForDay(day).map(post => (
                <PostCard key={post._id} post={post} />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <main className="flex-1 bg-gray-100 p-6">
          <div className="max-w-7xl mx-auto">
            {/* Calendar Header */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-4">
                <h1 className="text-2xl font-semibold text-gray-900">Calendar</h1>
                <div className="flex items-center bg-white rounded-lg shadow-sm p-1">
                  <button
                    onClick={() => setView('month')}
                    className={`px-3 py-1.5 rounded ${
                      view === 'month'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <LayoutGrid className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => setView('week')}
                    className={`px-3 py-1.5 rounded ${
                      view === 'week'
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <Menu className="w-4 h-4" />
                  </button>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex items-center bg-white rounded-lg shadow-sm">
                  <button
                    onClick={() => setCurrentDate(subMonths(currentDate, 1))}
                    className="p-2 hover:bg-gray-50 rounded-l-lg"
                  >
                    <ChevronLeft className="w-5 h-5 text-gray-600" />
                  </button>
                  <div className="px-4 py-2 font-medium">
                    {format(currentDate, 'MMMM yyyy')}
                  </div>
                  <button
                    onClick={() => setCurrentDate(addMonths(currentDate, 1))}
                    className="p-2 hover:bg-gray-50 rounded-r-lg"
                  >
                    <ChevronRight className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
                <button
                  onClick={() => setCurrentDate(new Date())}
                  className="px-4 py-2 bg-white text-gray-600 rounded-lg shadow-sm hover:bg-gray-50"
                >
                  Today
                </button>
              </div>
            </div>

            {/* Error Alert */}
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Loading State */}
            {loading ? (
              <div className="flex justify-center items-center h-[600px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              // Calendar Grid
              <div className="bg-white rounded-lg shadow-sm p-4">
                {view === 'month' ? renderMonthView() : renderWeekView()}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Calendar;