

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Zap, 
  TrendingUp, 
  Brain, 
  BarChart2, 
  Users, 
  Palette,
  ArrowRight,
  CheckCircle,
  Calendar,
  Clock,
  DollarSign,
  LineChart,
  AlignCenter,
  Footprints
} from 'lucide-react';

const HomePage = () => {
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate('/auth');
  };

  const features = [
    { 
      icon: Brain,
      title: 'Platform Algorithm Insights',
      description: 'Stay ahead with AI-powered insights that adapt to platform changes in real-time'
    },
    {
      icon: Zap,
      title: 'AI Content Generation',
      description: 'Create engaging, platform-optimized content that resonates with your audience'
    },
    {
      icon: BarChart2,
      title: 'Advanced Analytics',
      description: 'Make data-driven decisions with comprehensive performance metrics'
    },
    {
      icon: Users,
      title: 'Team Collaboration',
      description: 'Streamline your workflow with integrated team management tools'
    },
    {
      icon: Palette,
      title: 'Custom Branding',
      description: 'Maintain consistent brand identity across all social platforms'
    }
  ];

  const testimonials = [
    { name: 'Jane Doe', role: 'Influencer', comment: 'SocioPot has revolutionized my content strategy!' },
    { name: 'John Smith', role: 'Marketing Manager', comment: 'The analytics tools are a game-changer for our brand.' },
    { name: 'Alice Johnson', role: 'Small Business Owner', comment: 'I\'ve seen a 200% increase in engagement since using SocioPot.' }
  ];

  const challenges = [
    {
      icon: Clock,
      title: 'Traditional Approach',
      description: 'Months of waiting for results',
      solution: 'Results in Weeks'
    },
    {
      icon: LineChart,
      title: 'Inconsistent Performance',
      description: 'Unpredictable social media growth',
      solution: 'Steady Growth Path'
    },
    {
      icon: BarChart2,
      title: 'Complex Analytics',
      description: 'Overwhelming data without insights',
      solution: 'Clear, Actionable Insights'
    },
    {
      icon: Footprints,
      title: 'Outdated Strategies',
      description: 'Unable to keep up with algorithm changes',
      solution: 'AI-Powered Adaptation'
    },
    {
      icon: DollarSign,
      title: 'High Costs',
      description: 'Expensive social media teams',
      solution: 'Cost-Effective Solution'
    }
  ];

  const StarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </svg>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-blue-600">Socioblink</span>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={handleSignUp} className="px-4 py-2 text-blue-600 font-medium">
                Log in
              </button>
              <button 
                onClick={handleSignUp}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
              >
                Start Free Trial
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
  Drive Social Media Growth in{' '}
  <div className="relative inline-flex items-center">
    <div className="relative">
      <span className="relative z-10 font-handwritten">Months</span>
      <span className="absolute left-0 right-0 top-1/2 h-1 bg-red-500 transform -rotate-6" 
        style={{ width: '100%' }} // Constrains the line to the width of "Months"
      />
    </div>
    <span className="text-blue-600 ml-2 font-handwritten">Weeks</span>
  </div>
</h1>
            <p className="text-xl text-gray-600 mb-8">
              Your AI-powered social media manager that delivers exponential growth 
              through smart insights, advanced analytics, and platform-optimized content.
            </p>
            <div className="flex flex-col items-center justify-center space-y-4">
  <button
    onClick={handleSignUp}
    className="px-8 py-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center"
  >
    Get Started Free
    <ArrowRight className="ml-2 w-5 h-5" />
  </button>
  <div className="flex items-center text-gray-500 text-sm">
    <Calendar className="w-4 h-4 mr-2" />
    14-day free trial, no credit card required
  </div>
</div>
          </div>
        </div>
      </div>

      {/* Challenges Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Transform Your Social Media Strategy
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Leave behind traditional challenges and embrace the future of social media management
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {challenges.map((item, index) => (
              <div 
                key={index}
                className="group relative p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden"
              >
                <div className="absolute inset-0 bg-blue-600 transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out"></div>
                <div className="relative z-10 transition-colors duration-300">
                  <div className="w-12 h-12 bg-blue-100 group-hover:bg-white/20 rounded-lg flex items-center justify-center mb-4">
                    <item.icon className="w-6 h-6 text-blue-600 group-hover:text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 group-hover:text-white mb-2">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 group-hover:text-white/80 mb-3">
                    {item.description}
                  </p>
                  <div className="flex items-center text-blue-600 group-hover:text-white font-medium">
                    <ArrowRight className="w-4 h-4 mr-2" />
                    {item.solution}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Your Complete Social Media Growth Solution
            </h2>
            <p className="text-xl text-gray-600">
              Everything you need to achieve exponential growth on social media
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                  <feature.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

           {/* Testimonials Section */}
           <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center">What Our Customers Say</h2>
          <div className="mt-12 grid gap-8 lg:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-8">
                <div className="flex items-center mb-4">
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p className="text-gray-600 mb-4">{testimonial.comment}</p>
                <div className="font-medium text-gray-900">{testimonial.name}</div>
                <div className="text-gray-500">{testimonial.role}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Transform Your Social Media Presence?
            </h2>
            <p className="text-xl opacity-90 mb-8">
              Join thousands of businesses achieving rapid growth with Socioblink
            </p>
            <button
              onClick={handleSignUp}
              className="px-8 py-4 bg-white text-blue-600 rounded-lg font-medium hover:bg-gray-100 transition-colors inline-flex items-center"
            >
              Start Your Free Trial
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white py-6 border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center text-gray-600 text-sm">
            © {new Date().getFullYear()} Socioblink. All rights reserved.
          </div>
        </div>
      </footer>

      {/* Font Style */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

        .font-handwritten {
          font-family: 'Caveat', cursive;
          font-size: 1.2em;
          padding: 0 0.2em;
        }
      `}</style>
    </div>
  );
};

export default HomePage;



