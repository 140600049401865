import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isCurrentPath = (path) => {
    if (path === '/' || path === '/dashboard') {
      return currentPath === '/' || currentPath === '/dashboard';
    }
    return currentPath === path;
  };

  const navItems = [
    { path: '/dashboard', label: 'Home' },
    { path: '/calendar', label: 'Calendar' },
    { path: '/channels', label: 'Channels' },
    { path: '/analytics', label: 'Analytics' },
    { path: '/ai-suggests', label: 'AI Suggests' },
    { path: '/members', label: 'Members' },
  ];

  return (
    <div className="w-64 min-h-screen bg-[#1F2937] text-gray-300">
      <nav className="pt-6">
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`block px-6 py-3 text-base ${
              isCurrentPath(item.path)
                ? 'bg-[#374151] text-white'
                : 'hover:bg-[#374151] hover:text-white'
            }`}
          >
            {item.label}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;