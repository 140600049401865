// import React, { useState, useEffect } from 'react';
// import { X as XIcon, Calendar, Save } from 'lucide-react';
// import { Alert, AlertDescription } from './Alert';
// import AccountSelector from './AccountSelector';
// import PostInput from './PostInput';
// import ScheduleDialog from './ScheduleDialog';

// const EditPostDialog = ({ 
//   post, 
//   onClose, 
//   onUpdate, 
//   connectedAccounts = [],
//   isOpen 
// }) => {
//   // Initialize state with post data
//   const [formData, setFormData] = useState({
//     content: '',
//     selectedImage: null,
//     firstComment: '',
//     selectedAccounts: []
//   });
//   const [showScheduleDialog, setShowScheduleDialog] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');

//   // Update form data when post changes
//   useEffect(() => {
//     if (post && isOpen) {
//       console.log('Initializing form with post:', post); // Debug log
//       setFormData({
//         content: post.content || '',
//         selectedImage: post.content.media || null,
//         firstComment: post.content.firstComment || '',
//         selectedAccounts: post.platforms || []
//       });
//     }
//   }, [post, isOpen]);

//   const handleSaveAsDraft = async () => {
//     if (!validatePost()) return;
  
//     setIsLoading(true);
//     setError('');
  
//     try {
//       const token = localStorage.getItem('token');
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts/${post.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           content: {
//             text: formData.content.trim(),
//             media: formData.selectedImage,
//             firstComment: formData.firstComment.trim()
//           },
//           platforms: formData.selectedAccounts,
//           isDraft: true
//         })
//       });
  
//       if (!response.ok) {
//         const data = await response.json();
//         throw new Error(data.error || 'Failed to update draft');
//       }
  
//       const data = await response.json();
      
//       // Create a normalized post object
//       const normalizedPost = {
//         id: data.post.id || post.id,
//         content: {
//           text: formData.content.trim(),
//           media: formData.selectedImage,
//           firstComment: formData.firstComment.trim()
//         },
//         platforms: formData.selectedAccounts,
//         status: 'draft',
//         createdAt: data.post.createdAt || post.createdAt,
//         updatedAt: new Date().toISOString()
//       };
  
//       onUpdate(normalizedPost);
//       onClose();
  
//     } catch (err) {
//       console.error('Error updating post:', err);
//       setError(err.message || 'Failed to update post');
//     } finally {
//       setIsLoading(false);
//     }
//   };
  
//   const handleScheduleUpdate = async (scheduledDate, scheduledTime) => {
//     if (!validatePost()) return;
  
//     const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
//     if (scheduledDateTime <= new Date()) {
//       setError('Scheduled time must be in the future');
//       return;
//     }
  
//     setIsLoading(true);
//     setError('');
  
//     try {
//       const token = localStorage.getItem('token');
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts/${post.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           content: {
//             text: formData.content.trim(),
//             media: formData.selectedImage,
//             firstComment: formData.firstComment.trim()
//           },
//           platforms: formData.selectedAccounts,
//           scheduledAt: scheduledDateTime.toISOString(),
//           isDraft: false
//         })
//       });
  
//       if (!response.ok) {
//         const data = await response.json();
//         throw new Error(data.error || 'Failed to update post');
//       }
  
//       const data = await response.json();
  
//       // Create a normalized post object
//       const normalizedPost = {
//         id: data.post.id || post.id,
//         content: {
//           text: formData.content.trim(),
//           media: formData.selectedImage,
//           firstComment: formData.firstComment.trim()
//         },
//         platforms: formData.selectedAccounts,
//         status: 'scheduled',
//         createdAt: data.post.createdAt || post.createdAt,
//         scheduledAt: scheduledDateTime.toISOString(),
//         updatedAt: new Date().toISOString()
//       };
  
//       onUpdate(normalizedPost);
//       onClose();
  
//     } catch (err) {
//       console.error('Error updating post:', err);
//       setError(err.message || 'Failed to update post');
//     } finally {
//       setIsLoading(false);
//       setShowScheduleDialog(false);
//     }
//   };

//   const validatePost = () => {
//     if (!formData.content.trim()) {
//       setError('Post content cannot be empty');
//       return false;
//     }

//     if (formData.selectedAccounts.length === 0) {
//       setError('Please select at least one platform');
//       return false;
//     }

//     return true;
//   };

//   if (!isOpen || !post) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
//       <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col">
//         <div className="flex items-center justify-between p-4 border-b">
//           <h2 className="text-xl font-semibold">Edit Post</h2>
//           <button
//             onClick={onClose}
//             className="p-2 hover:bg-gray-100 rounded-full"
//           >
//             <XIcon className="w-5 h-5" />
//           </button>
//         </div>

//         <div className="flex-1 overflow-y-auto p-4">
//           {error && (
//             <Alert variant="destructive" className="mb-4">
//               <AlertDescription>{error}</AlertDescription>
//             </Alert>
//           )}

//           <AccountSelector
//             accounts={connectedAccounts.map(account => ({
//               id: account.toLowerCase(),
//               isConnected: true
//             }))}
//             selectedAccounts={formData.selectedAccounts}
//             onAccountToggle={(account) => {
//               setFormData(prev => ({
//                 ...prev,
//                 selectedAccounts: prev.selectedAccounts.includes(account)
//                   ? prev.selectedAccounts.filter(a => a !== account)
//                   : [...prev.selectedAccounts, account]
//               }));
//               setError('');
//             }}
//           />

//           <PostInput
//             content={formData.content}
//             onContentChange={(value) => {
//               setFormData(prev => ({ ...prev, content: value }));
//               setError('');
//             }}
//             selectedImage={formData.selectedImage}
//             onImageChange={(image) => {
//               setFormData(prev => ({ ...prev, selectedImage: image }));
//             }}
//             firstComment={formData.firstComment}
//             onFirstCommentChange={(comment) => {
//               setFormData(prev => ({ ...prev, firstComment: comment }));
//             }}
//             initialImage={post.content.media}
//           />
//         </div>

//         <div className="flex justify-end space-x-3 p-4 border-t">
//           <button
//             onClick={onClose}
//             disabled={isLoading}
//             className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleSaveAsDraft}
//             disabled={isLoading}
//             className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50 flex items-center"
//           >
//             <Save className="w-4 h-4 mr-2" />
//             Save as Draft
//           </button>
//           <button
//             onClick={() => setShowScheduleDialog(true)}
//             disabled={isLoading}
//             className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 flex items-center"
//           >
//             <Calendar className="w-4 h-4 mr-2" />
//             Schedule
//           </button>
//         </div>
//       </div>

//       {showScheduleDialog && (
//         <ScheduleDialog
//           onClose={() => setShowScheduleDialog(false)}
//           onSchedule={handleScheduleUpdate}
//           isLoading={isLoading}
//           initialDate={post.scheduledAt}
//         />
//       )}
//     </div>
//   );
// };

// export default EditPostDialog;

import React, { useState, useEffect } from 'react';
import { X as XIcon, Calendar, Save } from 'lucide-react';
import { Alert, AlertDescription } from './Alert';
import AccountSelector from './AccountSelector';
import PostInput from './PostInput';
import ScheduleDialog from './ScheduleDialog';

const EditPostDialog = ({ 
  post, 
  onClose, 
  onUpdate, 
  connectedAccounts = [],
  isOpen 
}) => {
  // Initialize state with post data
  const [formData, setFormData] = useState({
    content: '',
    selectedImage: null,
    firstComment: '',
    selectedAccounts: []
  });
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Update form data when post changes
  useEffect(() => {
    if (post && isOpen) {
      setFormData({
        content: post.content.text || '',
        selectedImage: post.content.media || null,
        firstComment: post.content.firstComment || '',
        selectedAccounts: post.platforms || []
      });
    }
  }, [post, isOpen]);

  const handleImageChange = (image) => {
    setFormData(prev => ({ 
      ...prev, 
      selectedImage: image 
    }));
  };

  const handleImageRemove = () => {
    setFormData(prev => ({ 
      ...prev, 
      selectedImage: null 
    }));
  };

  const validatePost = () => {
    if (!formData.content.trim()) {
      setError('Post content cannot be empty');
      return false;
    }

    if (formData.selectedAccounts.length === 0) {
      setError('Please select at least one platform');
      return false;
    }

    return true;
  };

  const handleSaveAsDraft = async () => {
    if (!validatePost()) return;
  
    setIsLoading(true);
    setError('');
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts/${post.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: {
            text: formData.content.trim(),
            media: formData.selectedImage,
            firstComment: formData.firstComment.trim()
          },
          platforms: formData.selectedAccounts,
          isDraft: true
        })
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update draft');
      }
  
      const data = await response.json();
      
      // Create a normalized post object
      const normalizedPost = {
        id: data.post.id || post.id,
        content: {
          text: formData.content.trim(),
          media: formData.selectedImage,
          firstComment: formData.firstComment.trim()
        },
        platforms: formData.selectedAccounts,
        status: 'draft',
        createdAt: data.post.createdAt || post.createdAt,
        updatedAt: new Date().toISOString()
      };
  
      onUpdate(normalizedPost);
      onClose();
  
    } catch (err) {
      console.error('Error updating post:', err);
      setError(err.message || 'Failed to update post');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleScheduleUpdate = async (scheduledDate, scheduledTime) => {
    if (!validatePost()) return;
  
    const scheduledDateTime = new Date(`${scheduledDate}T${scheduledTime}`);
    if (scheduledDateTime <= new Date()) {
      setError('Scheduled time must be in the future');
      return;
    }
  
    setIsLoading(true);
    setError('');
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/posts/${post.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: {
            text: formData.content.trim(),
            media: formData.selectedImage,
            firstComment: formData.firstComment.trim()
          },
          platforms: formData.selectedAccounts,
          scheduledAt: scheduledDateTime.toISOString(),
          isDraft: false
        })
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update post');
      }
  
      const data = await response.json();
  
      // Create a normalized post object
      const normalizedPost = {
        id: data.post.id || post.id,
        content: {
          text: formData.content.trim(),
          media: formData.selectedImage,
          firstComment: formData.firstComment.trim()
        },
        platforms: formData.selectedAccounts,
        status: 'scheduled',
        createdAt: data.post.createdAt || post.createdAt,
        scheduledAt: scheduledDateTime.toISOString(),
        updatedAt: new Date().toISOString()
      };
  
      onUpdate(normalizedPost);
      onClose();
  
    } catch (err) {
      console.error('Error updating post:', err);
      setError(err.message || 'Failed to update post');
    } finally {
      setIsLoading(false);
      setShowScheduleDialog(false);
    }
  };

  if (!isOpen || !post) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-xl font-semibold">Edit Post</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <AccountSelector
            accounts={connectedAccounts.map(account => ({
              id: account.toLowerCase(),
              isConnected: true
            }))}
            selectedAccounts={formData.selectedAccounts}
            onAccountToggle={(account) => {
              setFormData(prev => ({
                ...prev,
                selectedAccounts: prev.selectedAccounts.includes(account)
                  ? prev.selectedAccounts.filter(a => a !== account)
                  : [...prev.selectedAccounts, account]
              }));
              setError('');
            }}
          />

          <PostInput
            content={formData.content}
            onContentChange={(value) => {
              setFormData(prev => ({ ...prev, content: value }));
              setError('');
            }}
            selectedImage={formData.selectedImage}
            onImageChange={handleImageChange}
            onImageRemove={handleImageRemove}
            firstComment={formData.firstComment}
            onFirstCommentChange={(comment) => {
              setFormData(prev => ({ ...prev, firstComment: comment }));
            }}
          />
        </div>

        <div className="flex justify-end space-x-3 p-4 border-t">
          <button
            onClick={onClose}
            disabled={isLoading}
            className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveAsDraft}
            disabled={isLoading}
            className="px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50 flex items-center"
          >
            <Save className="w-4 h-4 mr-2" />
            Save as Draft
          </button>
          <button
            onClick={() => setShowScheduleDialog(true)}
            disabled={isLoading}
            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 flex items-center"
          >
            <Calendar className="w-4 h-4 mr-2" />
            Schedule
          </button>
        </div>
      </div>

      {showScheduleDialog && (
        <ScheduleDialog
          onClose={() => setShowScheduleDialog(false)}
          onSchedule={handleScheduleUpdate}
          isLoading={isLoading}
          initialDate={post.scheduledAt}
        />
      )}
    </div>
  );
};

export default EditPostDialog;